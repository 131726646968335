<template>
  <div class="container-fluid" v-if="!refreshingReport">
    <div class="row">
      <h1>Quiz Report for {{ quiz.quizTitle }}</h1>
      <div class="card">
        <div class="card-body">
          <h4>Quiz Description:</h4>
          <h5>{{ quiz.description }}</h5>
        </div>
        <hr/>
        <div class="card-body">
          <button type="submit" class="button btn-primary" @click="updateReport">Refresh This Report</button>
        </div>

      </div>
    </div>
    <div class="row accordion mt-2" v-for="report in quiz.quizReportStats" :key="report" :id="quiz.idQuiz">
      <div class="accordion-item " v-for="question in report.questions" :key="question">
        <QuizQuestionReport :question-data="question"/>
      </div>
    </div>
  </div>
  <div v-else>
    <Loader :loading-value="'Refreshing this quiz report'" />
  </div>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {singleQuizReport, updateSingleQuizFromCanvas} from "@/views/SuperUserViews/Reporting/Quizzes/QuizApiCalls";
import QuizQuestionReport from "@/views/SuperUserViews/Reporting/Quizzes/QuizQuestionReport";
import {useStore} from "vuex";
import Swal from "sweetalert2";
import Loader from "@/components/Loader";

export default {
  name: "QuizReport",
  components: {Loader, QuizQuestionReport},
  props: {
    idQuiz: Number
  },
  setup(props, context) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const quiz = ref({});
    const refreshingReport = ref(false);

    onBeforeMount(() => {
      getQuiz();
    })

    async function getQuiz() {
      await axios.get(singleQuizReport() + props.idQuiz)
          .then((result) => {
            quiz.value = result.data;
          }).finally(() => {
            quiz.value.quizReportStats[0].questions.sort((a, b) => a.sortPosition > b.sortPosition ? 1 : -1);
          })
      refreshingReport.value = false;
    }

    async function updateReport() {
      refreshingReport.value = true;
      await axios.get(updateSingleQuizFromCanvas() + props.idQuiz, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then(() => {
        Swal.fire({
          title: 'Updated Quiz Reports',
          icon: 'success'
        })
      }).finally(() => {
        getQuiz();
      })
    }

    function downloadQuizReportData() {

    }

    return {
      quiz,
      refreshingReport,
      downloadQuizReportData,
      updateReport
    }
  }
}
</script>

<style scoped>

</style>