import {API_URL} from "../../../../../Constants";

export const updateQuizReports = () => {
    return API_URL + "/quiz/updateStats";
}

export const updateQuizzesFromCanvas = () => {
    return API_URL + "/quiz/update";
}

export const getQuizDisplays = () => {
    return API_URL + "/quiz/displayAll";
}

export const singleQuizReport = () => {
    return API_URL + "/quiz/report/byQuizId?_idQuiz=";
}

export const updateSingleQuizFromCanvas = () => {
    return API_URL + "/quiz/report/update/byQuizId?_idQuiz="
}

export const updateQuizByCourseId = (courseId) => {
    return `${API_URL}/quiz/update/${courseId}`;
}