<template>
  <div class="accordion-item mb-1" >
    <span>{{ answerData.answerText }}</span>
    <div>
      <span>{{ answerData.responses }} students selected this response</span>
    </div>
    <div v-if="answerData.correct !== null">
      <span v-if="answerData.correct" style="color: darkgreen">Correct</span>
      <span v-else style="color: red;">Incorrect</span>
    </div>
    <div v-else>
      <span>This question is not graded</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "QuizAnswerReport",
  props: {
    answerData: Object
  },
  setup(props, context) {


  }
}
</script>

<style scoped>

</style>