<template>
    <h2 class="accordion-header" :id="questionData.idQuizQuestion">
      <button class="accordion-button collapsed" type="button" data-toggle="collapse"
              :data-target="'#question-item-' + questionData.idQuizQuestion"
              aria-expanded="false"
              :aria-controls="'question-item-' + questionData.idQuizQuestion">
        <div class="col-sm">{{questionData.questionText}}</div>
      </button>
    </h2>
    <div :id="'question-item-' + questionData.idQuizQuestion"
         class="accordion-collapse collapse" :aria-labelledby="questionData.questionText + questionData.idQuestion" style="text-align: left;">
      <p>{{questionData.responses !== null ? questionData.responses : ""}}</p>
      <div v-for="answer in answers" :key="answer">
        <QuizAnswerReport :answer-data="answer" />
      </div>
    </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import QuizAnswerReport from "@/views/SuperUserViews/Reporting/Quizzes/QuizAnswerReport";


export default {
  name: "QuizQuestionReport",
  // eslint-disable-next-line vue/no-unused-components
  components: {QuizAnswerReport},
  props: {
    questionData: Object,
  },
  setup(props, context) {
    const answers = ref([]);

    onBeforeMount(() => {
      sortAnswers()
    })

    watch(() => props.questionData, () => {
      sortAnswers();
    })

    const sortAnswers = () => {
      answers.value = props.questionData.answers;
      answers.value = answers.value.sort((a, b) => a.answerText > b.answerText ? 1 : -1);
    }

    return {
      answers
    }
  }
}
</script>

<style scoped>

</style>